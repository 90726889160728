<template>
  <div id="LOCATION_BADGE_GROUP_MODAL" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Location"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t(`project.field.location`) }}</label>
        <button class="btn-action" @click="locationSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="locations">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="locationBadgeRemove(index)"
              :text="`${item.name}`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('badgeGroupError') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <LocationSelectorModal v-if="locationSelectorShow" :show.sync="locationSelectorShow" :multiple="multiple" @cancel="locationSelectorCancel" @ok="locationSelectorOk"/>
    
  </div>
</template>

<script>
import { objectClone } from '@/helpers'
import { fieldValidateUtil } from '@/script/helper-field-validate'

export default {
  name: 'LocationBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    LocationSelectorModal: () => import('@/components/modal/LocationSelectorModal'),
    
  },
  props: {
    show: { type: Boolean, required: true }
    , locationList: { type: Array, default: () => [] }
    , multiple: { type: Boolean, default: false }
  },
  data() {
    return {
      modalShow: false
      , locationSelectorShow: false
      , locations: []
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil
    this.modifiedList = []
  },
  beforeMount() {
    this.locations.splice(0, this.locations.length, ...this.locationList)
    this.modalShow = true
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
    this.fieldValidateUtil = null
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.errors.clear()
        this.modifiedList.splice(0, this.modifiedList.length)
        this.modalShow = newValue
        if (newValue) {
          this.locations.splice(0, this.locations.length, ...this.locationList)
        }
      }
    }
  },
  computed: {
    showError() {
      return fieldValidateUtil.hasError(this.errors, 'badgeGroupError');
    },
  },
  methods: {
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , locationSelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.locationSelectorShow = false
    }
    , locationBadgeRemove: function(index) {
      this.locations.splice(index,1)
    }
    , locationSelectorToggle() {
      this.locationSelectorShow = true
    }
    , locationSelectorOk({ details }) {
      if (!this.multiple) {
        this.locations.splice(0, this.locations.length)
      }
      this.locations.push( ...details.map(i => { return { uuId: i.uuId, name: i.name }}) )
    }
    , ok() {
      this.errors.clear()
      if (!this.isOptional && this.locations.length < 1) {
        this.errors.add({
          field: 'badgeGroupError',
          msg: this.$i18n.t('error.missing_argument', [this.$t(`project.field.location`)])
        })
      }

      this.$nextTick(() => {
        if (this.errors.items.length < 1) {
          this.$emit('ok', objectClone(this.locations))
          this.$emit('update:show', false)
        }
      })
    }
  }
}
</script>